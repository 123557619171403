/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://m7osdf2nwfayvmhvi6xjd73vu4.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-hc6hr56tq5dinoxllk2ywjrfpi",
    "aws_cognito_identity_pool_id": "eu-west-1:9a2479d2-c452-45b0-8912-b02869667490",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Mu2bOpNDz",
    "aws_user_pools_web_client_id": "1enqb5hf7jhl246ar3a0tg9ovi",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "govdata-exporter133620-prd",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
